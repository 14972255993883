<template>
  <vs-card class="templates">
    <header slot="header">
      <div>
        <div class="templates-header">
          <div>
            <h1 class="templates-header__title">
              Meus Templates

              <span
                class="material-icons-outlined"
                style="color: #636366;"
              >
                chevron_right
              </span>
            </h1>

            <div class="mt-2">
              <p class="templates-header__subtitle text-secondary">
                Bem-vindo a gestão de Templates.
                Aqui você poderá visualizar, editar e criar novos templates de e-mail.
              </p>
            </div>
          </div>

          <div class="templates-header__actions">
            <vs-button
              icon="add"
              @click="goToCreateMenu"
            >
              Novo Template
            </vs-button>
          </div>
        </div>
      </div>
    </header>

    <!-- Client Templates -->
    <main class="templates-container">
      <TemplateFilters
        :search="search"
        :user-update="userUpdateFilter"
        :users="users"
        @set-search="(val) => search = val"
        @set-user-update="(val) => userUpdateFilter = val"
      />

      <Carousel
        v-if="filteredTemplates.length > 0"
        id="owner-templates"
        :slides="filteredTemplates"
        @call-expand-popup="callExpandPopup"
        @delete-item="confirmDelDialog"
        @edit-item="goToBuilder"
        @select-item="selectTemplate"
      />
      <span
        v-else
        class="mb-3"
      >
        Nenhum template encontrado.
      </span>
    </main>

    <!-- Popups -->
    <!-- Expand Preview Popup -->
    <vs-popup
      :active.sync="expandPopup"
      :title="selectedTemplate.title"
    >
      <div class="expand-preview-popup">
        <img
          :src="selectedTemplate.urlPreview"
          alt="Preview indisponível"
        >
      </div>
    </vs-popup>
  </vs-card>
</template>

<script>
/* Components */
import Carousel from './Carousel.vue';
import TemplateFilters from './TemplateFilters.vue';

/* Data */
import getBlocks from './TemplateBuilder/data/blocks';

/* Helpers */
import moment from 'moment';
import appendDataLocator from './TemplateBuilder/helpers/append-data-locator';
import translateBlockToHtml from './TemplateBuilder/helpers/translate-block-to-html';

/* Services */
const configDomain = process.env.VUE_APP_CONFIGDOMAIN;
import Template from '@/services/template';

const templateService = new Template();

export default {
  name: 'Templates',
  components: {
    Carousel,
    TemplateFilters,
  },
  data: () => ({
    templates: [],
    users: [],
    selectedTemplate: {},
    search: '',
    userUpdateFilter: '',
    /* Popups */
    expandPopup: false,
  }),
  computed: {
    /* Store */
    userLogin() {
      return this.$store.state.loginData.userLogin;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    /* Data */
    blocks() {
      const imageSlotUrl = `${configDomain}/images/template-builder/image-slot.png`;
      const logo = this.$store.state.emailTemplateLogo;
      const btnColor = this.$store.state.configBox.bgCardColors;
      const logoWidth = this.$store.state.configBox.logoWidth;
      const ownerCopyright = this.$store.state.ownerCopyright;

      const tokens = {
        btnColor,
        imageSlotUrl,
        logo,
        logoWidth,
        ownerCopyright,
      };

      return getBlocks(tokens);
    },
    filteredTemplates() {
      let filteredTemplates = this.templates;

      if (this.search.length > 0) {
        const searchLowercase = this.search.toLowerCase();

        filteredTemplates = filteredTemplates.filter((t) => {
          const titleLowercase = t.title.toLowerCase();
          return titleLowercase.includes(searchLowercase);
        });
      }

      if (this.userUpdateFilter.length > 0) {
        filteredTemplates = filteredTemplates.filter((t) => {
          if (t.userUpdate) {
            if (this.userUpdateFilter === 'Eu') {
              return t.userUpdate === this.userLogin;
            } else if (t.userUpdate === this.userUpdateFilter) {
              return t;
            }
          }
        });
      }

      return filteredTemplates;
    },
    /* Get next logical ref to save webversion template */
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  created() {
    // Get template files
    this.getTemplates();
  },
  methods: {
    /* API */
    getTemplates() {
      this.$store.dispatch('set_isLoadingActive', true);

      templateService.getTemplates(this.ownerId)
        .then((resp) => {
          // Add content GET request to each template
          const requests = resp.map(async (t) => {
            const {
              content,
              fileId,
              created,
              lastUpdate,
              userUpdate,
            } = await this.getTemplateByRef(t.id.toString());

            let info = [];

            if (userUpdate) info.push(`Por: ${userUpdate}`);

            if (created) {
              info.push(`Data de criação: ${this.getDate(created)}`);
            }

            if (!lastUpdate) {
              info.push(`Última atualização: ${this.getDate(created)}`);
            } else {
              info.push(`Última atualização: ${this.getDate(lastUpdate)}`);
            }

            return {
              ...t,
              fileId,
              content: content || [],
              cardType: 'image',
              image: t.urlPreview,
              title: t.value,
              info,
              userUpdate,
            };
          });

          // Handle asynchronous requests
          Promise.all(requests)
            .then((results) => {
              // Translate rows' blocks to HTML content for the front-end
              const translatedTemplates = results.map((t) => {
                const rows = [...t.content];

                const translatedRows = rows.map((row) => {
                  const cells = row.cells.map((cell) => {
                    let translatedCell = {
                      id: cell.id,
                      colspan: cell.colspan,
                      style: cell.style,
                    };

                    // Get block content
                    const block = this.blocks.find((b) => b.id === cell.block.id);
                    const html = translateBlockToHtml(block.template, cell.block.props);
                    const content = appendDataLocator(html, cell.id);

                    translatedCell.block = block;
                    translatedCell.content = content;

                    return translatedCell;
                  });

                  return { ...row, cells };
                });

                return { ...t, content: translatedRows };
              });

              this.templates = translatedTemplates;
              this.setUsers();
            })
            .finally(() => this.$store.dispatch('set_isLoadingActive', false));
        });
    },
    async getTemplateByRef(fileRef) {
      let template = {};

      await templateService.getTemplateByRef(fileRef, this.token)
        .then((resp) => (template = resp))
        .catch(() => (template = {}));

      return template;
    },
    async deleteTemplate() {
      this.$store.dispatch('set_isLoadingActive', true);

      await this.deleteTemplateFile(this.selectedTemplate.id);

      templateService.deleteTemplate(
        this.selectedTemplate.id,
        this.token,
      )
        .then(() => (this.deleteSuccessAlert()))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.getTemplates();
        });
    },
    async deleteTemplateFile() {
      return templateService.deleteTemplateFile(this.selectedTemplate.id);
    },
    /* Helpers */
    getDate(strdate) {
      if (strdate) {
        const strArray = strdate.split('T');

        const date = strArray[0].replaceAll('-', '/');
        const parsedDate = moment(date, 'YYYY/MM/DD');
        const formattedDate = parsedDate.format('DD/MM/YYYY')

        return formattedDate;
      } else {
        return '';
      }
    },
    setUsers() {
      const users = this.templates.map((template) => template.userUpdate);
      const set = new Set(users);
      let array = Array.from(set);

      array = array.map((user) => {
        const isLoginUser = user === this.userLogin;

        let option = {
          text: isLoginUser ? 'Eu' : user,
          value: isLoginUser ? 'Eu' : user,
        };

        return option;
      });

      array.unshift({ text: '', value: '' });

      this.users = array;
    },
    /* Alerts */
    deleteSuccessAlert() {
      this.$vs.notify({
        title: 'Template deletado',
        color: 'success',
        text: `"${this.selectedTemplate.title}" deletado com sucesso.`
      });
    },
    /* Popups */
    callExpandPopup() {
      this.expandPopup = true;
    },
    confirmDelDialog() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Deletar Template',
        text: `Tem certeza que quer deletar "${this.selectedTemplate.title}"?`,
        accept: this.deleteTemplate,
        cancelText: 'Cancelar',
        acceptText: 'Deletar',
      });
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
    },
    /* Router */
    goToBuilder() {
      this.$router.push({
        name: 'TemplateBuilder',
        params: {
          mode: 'edit',
          selectedTemplate: this.selectedTemplate,
        },
      });
    },
    goToCreateMenu() {
      this.$router.push('/templates/create');
    },
  },
};
</script>

<style lang="scss">
.template-card__custom-hr {
  margin: 1rem 0 2rem;
}

.expand-preview-popup {
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    width: 100%;
  }
}

.templates {
  h2, h3 {
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.35em;
  }

  h3 {
    font-size: 1em;
  }

  .templates-header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    .templates-header__title {
      align-items: center;
      display: flex;
      font-size: 1.35em;
      margin-top: 3px;
    }

    .templates-header__subtitle {
      font-size: 0.975em;
      font-weight: 400;
      padding-top: 0.5rem;
      margin-bottom: 0;
    }

    .templates-header__actions {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;

      h1 {
        font-size: 1.75em;
      }
    }
  }

  .templates-container {
    display: flex;
    flex-flow: column wrap;
    gap: 1rem;
  }
}
</style>
