<template>
  <div class="template-filters">
    <vs-input
      v-model="searchModel"
      icon="search"
      icon-after="true"
      placeholder="Pesquisar template"
    />

    <div class="d-flex align-items-center text-secondary">
      <i class="mdi mdi-filter-variant mr-2" />

      <span
        class="mr-2"
        :style="{ height: '16px', borderLeft: '1px solid rgba(0,0,0,.1)' }"
      />

      <!-- User Update Filter -->
      <div
        class="filter"
        @mouseleave="() => deactivateAutocomplete('userUpdate')"
      >
        <vs-tooltip
          text="Filtrar templates por dono"
          position="top"
        >
          <button
            class="filter__button"
            @click="() => activateAutocomplete('userUpdate')"
          >
            <vs-chip
              class="m-0"
              :color="userUpdate.length > 0 ? chipColorActive : null"
              :closable="userUpdate.length > 0"
              @click="closeUserChip"
            >
              <vs-avatar />

              {{ userUpdate }}
            </vs-chip>
          </button>
        </vs-tooltip>

        <vs-select
          id="userUpdate"
          v-model="userUpdateModel"
          class="filter__autocomplete"
          autocomplete
        >
          <vs-select-item
            v-for="item in users"
            :key="item.value"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateFilters',
  props: {
    users: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    userUpdate: {
      type: String,
      required: true,
    },
  },
  computed: {
    /* Data */
    chipColorActive() {
      return this.$store.state.configBox.bgCardColors;
    },
    /* Models */
    searchModel: {
      get() { return this.search; },
      set(value) { this.$emit('set-search', value); },
    },
    userUpdateModel: {
      get() { return this.userUpdate; },
      set(value) { this.$emit('set-user-update', value); },
    },
  },
  methods: {
    /* Helpers */
    activateAutocomplete(autocompleteId) {
      const target = document.getElementById(autocompleteId);
      target.classList.add('autocomplete-active');
    },
    deactivateAutocomplete(autocompleteId) {
      const target = document.getElementById(autocompleteId);
      target.classList.remove('autocomplete-active');
    },
    closeUserChip() {
      this.$emit('set-user-update', '');

      setTimeout(() => {
        this.deactivateAutocomplete('userUpdate');
      }, 50);
    },
  },
};
</script>

<style lang="scss">
.template-filters {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;

  .filter {
    align-items: center;
    display: flex;
    gap: 0.5rem;

    .con-vs-tooltip {
      display: flex;

      .filter__button {
        appearance: none;
        background: transparent;
        border: 0;
        box-shadow: none;
        outline: 0;
      }
    }

    .filter__autocomplete {
      padding: 0;

      input,
      .con-select,
      .input-select-con {
        border-radius: 20px;
        width: 0px;
        transition: 0.3s;

        .vs-select--input {
          height: 0;
          padding: 0;
          transition: 0.3s;
        }

        .vs-select--icon-clear,
        .icon-select.vs-select--icon {
          display: none;
          transition: 0.3s;
        }
      }
    }

    .autocomplete-active {
      input,
      .con-select,
      .input-select-con {
        width: 200px;

        .vs-select--input {
          height: auto;
          padding: 4px 7px;
        }

        .vs-select--icon-clear,
        .icon-select.vs-select--icon {
          display: block;
        }
      }
    }
  }
}
</style>
